.swal-custom-btn {
    color: var(--color-white);
    background-color: var(--color-black);
    padding: 8px 40px;
    border-radius: 12px;
    font-size: var(--font-size-m);
    border: none;
}

.swal-custom-btn:hover {
    background-color: var(--darker-grey);
    cursor: pointer;
}

.swal-custom-input {
    width: 70%;
    border: 1px solid var(--lighter-grey);
    border-radius: 7px;
    padding: 10px;
    font-size: var(--font-size-s);
}

.swal-custom-title {
    font-size: var(--font-size-xxl);
    font-weight: 600;
}