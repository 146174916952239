
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "nouislider/dist/nouislider.css";
// Plus imports for other components in your app.

@import 'bootstrap/scss/bootstrap';
@import 'animate.css';
@import './app/shared/styles/custom-swal.scss';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$V2CNewCloud-primary: mat.define-palette(mat.$indigo-palette);
$V2CNewCloud-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$V2CNewCloud-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$V2CNewCloud-theme: mat.define-light-theme((
  color: (
    primary: $V2CNewCloud-primary,
    accent: $V2CNewCloud-accent,
    warn: $V2CNewCloud-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($V2CNewCloud-theme);

/* You can add global styles to this file, and also import other style files */
:root {
    --ghost-white: #f6f5fa;
    --light-grey: #aaa;
    --lighter-grey: #ccc;
    --lightest-grey: #eee;
    --dark-grey: #666;
    --darker-grey: #444;
    --darkest-grey: #222;
    --color-white: #fffffd;
    --color-black: #0c0c0c;
    --color-red: #d41111;
  
    /* font sizes */
    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-ms: 13px;
    --font-size-m: 14px;
    --font-size-ml: 15px;
    --font-size-l: 16px;
    --font-size-xl: 18px;
    --font-size-xxl: 20px;
}

* {
    font-family: 'Montserrat', sans-serif;
}

body, html {
    margin: 0;
    padding: 0;
    width: fit-content;
    min-width: 100%;
    height: fit-content;
    min-height: 100%;
}

body {
   background-color: #f6f5fa;
   color: var(--color-black);
}

main {
  height: 0 !important;
  width: 0 !important;
}

main > canvas {
  height: 0 !important;
  width: 0 !important;
}

.joined {
    white-space: nowrap;
}

.tooltip .tooltip-inner {
  background-color: var(--lighter-grey);
}

.tooltip .tooltip-arrow::before {
  display: none;
}

.fade {
  opacity: 1 !important;
}

.noUi-handle {
  border: none;
  background: none;
  cursor: pointer;
  box-shadow: none;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
  background: black;
  border-radius: 50%;
}

.noUi-horizontal .noUi-handle {
  width: 22px;
  height: 22px;
  right: -17px;
  top: -6px;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: none;
  left: 14px;
  top: 6px;
}

.noUi-horizontal {
  height: 12px;
}

.noUi-target {
  background: #d1d1d1;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #000000 !important;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38) !important;
  --mat-tab-header-pagination-icon-color: black !important;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-tab-header-active-label-text-color: #000000 !important;
  --mat-tab-header-active-ripple-color: #000000 !important;
  --mat-tab-header-inactive-ripple-color: #000000 !important;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-tab-header-active-focus-label-text-color: #000000 !important;
  --mat-tab-header-active-hover-label-text-color: #000000 !important;
  --mat-tab-header-active-focus-indicator-color: #000000 !important;
  --mat-tab-header-active-hover-indicator-color: #000000 !important;
}

/* Ajusta el padding o margen del texto seleccionado dentro del ng-select */
.ng-select .ng-value {
  padding-left: 10px; /* Espacio a la izquierda del texto */
  padding-top: 6px; /* Espacio en la parte superior */
  padding-bottom: 4px; /* Espacio en la parte inferior */
  display: flex; /* Asegura la alineación adecuada */
  align-items: center; /* Centra el texto verticalmente */
}

.ng-select .ng-value-container {
  height: 38px !important;
}

.ng-select input {
  padding: 7px !important;
  cursor: pointer !important;
}

/* Estilo para cada opción seleccionada */
.ng-select .ng-value-label {
  padding-left: 5px; /* Ajusta el espacio dentro del valor seleccionado */
}
/* Estilos para el panel desplegable */
.ng-dropdown-panel {
  background-color: white;
  border: 1px solid gray;
  margin-top: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Estilos para cada opción en la lista */
.ng-dropdown-panel .ng-option {
  background-color: #f9f9f9;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

/* Estilos para el texto de cada opción */
.ng-dropdown-panel .ng-option-label {
  font-size: 16px;
  color: #333;
  padding-left: 15px;
}

/* Estilo para la opción seleccionada */
.ng-dropdown-panel .ng-option.selected {
  background-color: #d3e3ff; /* Fondo diferente para la opción seleccionada */
}

/* Estilo para la opción cuando se pasa el mouse por encima */
.ng-dropdown-panel .ng-option:hover {
  background-color: #e0f0ff; /* Fondo diferente al pasar el mouse */
  cursor: pointer;
}

.my-actions {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto; /* Para centrar el contenido */
}

.my-text {
  margin-bottom: 15px !important;
}

.my-confirm-button {
  background-color: black !important; /* Fondo negro */
  color: white !important; /* Texto en blanco */
  border: none !important; /* Sin borde */
  padding: 10px 20px; /* Ajustar el tamaño del botón */
  font-size: 16px; /* Tamaño de fuente */
  border-radius: 5px; /* Bordes redondeados */
}


.charger-checkbox-popup {
  margin-right: 12px;
  border-radius: 2px;
  border: 1px solid var(--color-black);
  height: 15px;
  width: 15px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#category-img-check-popup {
  opacity: 0; 
  width: 9px
}

.text-label-popup {
  font-size: 12px;
}

.container-checkbox-popup {
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.container-input-popup {
  width: 80%;
  margin: auto; 
  margin-bottom: 10px
}

.label-input-popup {
  width: 100%; 
  margin-bottom: 5px; 
  font-size: var(--font-size-s); 
  text-align: start;
}

.input-popup {
  border-radius: 10px; 
  border: 1px solid var(--lighter-grey); 
  font-size: var(--font-size-s); 
  padding-left: 10px; 
  padding-right: 10px; 
  width: 100%; 
  height: 30px;
}